import React, { useState } from 'react'
import {motion} from 'framer-motion'

// childre is element that wrappeed inside a Toggle components i FaqSection.js
const Toggle = ({children, title}) => {
    const [toggle, setToggle] = useState(false)
    return (
        <motion.div layout className='question' onClick={() => setToggle(!toggle)}>
            {/* if toggle is true render out the childre elements if flase don't render out anything*/}
            <motion.h4 layout>{title}</motion.h4>
            {toggle ? children : ""}
            <div className='faq-line'></div>
        </motion.div>
    )
}

export default Toggle
