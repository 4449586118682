import React from 'react'
import {Link} from 'react-router-dom'
import HeaderImage from'../img/HeaderImage.png'

//Styled
import styled from 'styled-components'
import {StyledAbout, StyledDescription,  StyledImage, StyledHide} from '../styles'

//Framer motion
import {motion} from 'framer-motion'
import { titleSecondAnim, fade, photoAnim } from '../animation'
import Wave from './Wave'

const AboutSection = () => {

    //Create const for propriety of Framer Motion Animation
    const titleAnim = {
        hidden: {opacity : 0, x : 40, y: -20},
        show : {opacity : 1, transition: {duration: 2}, x: 0, y: 0}
    }

    const containerAnim = {
        hidden: {x : 100},
        show: {x : 0, transition: { duration : 0.75, ease: "easeOut", staggerChildren: 1, when: "afterChildren"}}
    }

    return (
        <StyledAbout>
            <Description>
                <motion.div /*variants={containerAnim} initial="hidden" animate="show"*/ className='title'>
                    <div className='hide'>
                        <motion.h2 variants={titleSecondAnim} >Dr. <span>Michele Berloco</span> </motion.h2>
                    </div>
                    <div className='hide'>
                        <motion.h2 variants={titleAnim}>Ortopedia e Chirurgia della mano</motion.h2>
                    </div>

                    <div className='hide_3'id='hide_3'>
                        <motion.h2 variants={titleAnim} id='Texthide_3' >Il dott. Michele Berloco vi dà il benvenuto</motion.h2>
                    </div>
                    
                </motion.div>
                <motion.p variants={fade}>Il dott. Berloco, specialista in Ortopedia e Traumatologia,
                     è socio ordinario della Società Italiana Chirurgia della Mano e Segretario Regionale degli Ortopedici Traumatologi Ospedalieri d’Italia
                </motion.p>
                <Link to='/Contact'>
                <motion.button variants={fade}>Contatta il Dr.Berloco</motion.button>
                </Link>
            </Description>
            <StyledImage>
                <motion.img variants={photoAnim} src={HeaderImage} alt='image1'></motion.img>
            </StyledImage>
            <Wave />
        </StyledAbout>
    )
}

//Styled Components
const Description = styled(StyledDescription)`
  #Texthide_3 {
      font-size: 40px;
      margin-top: 3rem
  }
`;



export default AboutSection
