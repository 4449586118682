import React from 'react'
import styled from 'styled-components'
import { css } from 'styled-components'
import emailjs from "emailjs-com"

//import components
import Form from '../../components/Form'
import Label from '../../components/Label'
import StyledButton from '../../components/Button'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'

const WrapperGrid = styled.div`
    ${props => props.full && css`
        grid-column: 1 / 3;
    `}
`;

function sendEmail(e)  {
    e.preventDefault()
    alert("ok")

    emailjs.sendForm('francescobocchini91','template_mjqgspv', e.target,
     'user_6zv6F19lVS9krK4QnjgZV').then(res => {
         console.log(res)
     }).catch(err => { console.log(err)})
};

const ContactForm = () => (

    <div>
        <h3>Email Us</h3>
        <Form>
            <WrapperGrid>
                <Label>Nome</Label>
                <Input type="text" name="name" />
            </WrapperGrid>
            <WrapperGrid>
                <Label>Cognome</Label>
                <Input type="text" name="company" />
            </WrapperGrid>
            <WrapperGrid>
                <Label>Indirizzo Email</Label>
                <Input type="email" name="email" />
            </WrapperGrid>
            <WrapperGrid>
                <Label>Numero di telefono</Label>
                <Input type="text" name="phone" />
            </WrapperGrid>
            <WrapperGrid full>
                <Label>Messaggio</Label>
                <Textarea name="message" rows="5"></Textarea>
            </WrapperGrid>
            <WrapperGrid full>
                <StyledButton onClick={sendEmail}>Invia</StyledButton>
            </WrapperGrid>
        </Form>
    </div>
)

export default ContactForm