import React from 'react'

function TemporaryAnswarePage() {
    return (
        <div>
            <h1>Pagina in allestimento</h1>
        </div>
    )
}

export default TemporaryAnswarePage
