
import piastrinico from './img/piastrinico.jpg'
import home1 from './img/home1.png'
import gel from './img/gel.jpg'
import Frattura from './img/Frattura.jpg'
import Tendini from './img/Tendini.jpg'
import TunnelCarpale from './img/TunnelCarpale.jpg'
import Artrosi from './img/Artrosi.jpeg'
import assicurazioni from './img/assicurazioni.jpg'


export const PatologyObj = () =>{
    return  [
        {   title : 'Gel Piastrinico',
            mainImg: piastrinico,
            secondaryimg: "",
            url: '/Work/Gel',
            awards : [
                {
                    title: 'Perchè il Gel Piastrinico',
                    description : 'Il potenziale valore terapeutico delle Piastrine e la versatilità dei loro prodotti hanno recentemente stimolato la ricerca e l’interesse nel campo della medicina rigenerativa.'
                },
                {
                    title: 'Cosa sono le piastrine?',
                    description : 'e Piastrine rilasciano in circolo i seguenti fattori di crescita: basic Fibroblast Growth Factor (bFGF ), Platelet-derived Growth Factor AB (PDGF-AB), Insulin like Growth Factor (IGF-1),Vascular Endothelial Growth Factor (VEFG) che stimolano la proliferazione di fibroblasti, cheratinociti, melanociti, cellule dell’endotelio, periciti, miociti, condrociti, osteoclasti, cellule mesenchimali, elementi che sono alla base della rigenerazione tissutale, inoltre grazie all’azione di citochine e chemocine, metalloproteasi della matrice (MMPs), inibitori delle metallo proteasi (TIMPs) e la serotonina mediano i processi infiammatori cellulari.'
                },
                {
                    title: 'Ambiti di applicabilità',
                    description : 'Attualmente, l’utilizzo del gel piastrinico o Plasma Ricco di Piastrine (PRP) ha trovato ampio impiego in chirurgia Ortopedica, Odontostomatologica e Plastica grazie alla spiccata capacità rigenerativa ed antiinfiammatoria delle componenti piastriniche. Il gruppo di ricerca della Struttura Complessa di Ortopedia, in collaborazione con il servizio Immnunotrasfusionale dell’Azienda Ospedaliera di Foligno, utilizza in gel piastrinico  per i processi degenerativi osteoarticolari e per le tendinopatie.'
                }
            ]
        },
        {   title : 'Sindromi canalicolari',
            mainImg: TunnelCarpale,
            secondaryimg: TunnelCarpale,
            url: '/Work/canalicolari',
            awards : [
                {
                    title: 'Sindrome del tunnel carpale',
                    description : 'La sindrome del tunnel carpale è un disturbo da compressione nervosa abbastanza comune, che causa dolore, senso di intorpidimento e formicolio a polso, mano e dita. La causa di tale problema è raramente una sola; infatti, di solito, la sindrome del tunnel carpale è il risultato di una combinazione di diversi fattori.'
                },
                {
                    title: 'Compressione del Nervo Ulnare',
                    description : 'La sindrome da compressione del nervo ulnare è una delle patologie più frequenti del gomito ed è provocata dalla compressione del nervo ulnare nel canale cubitale (un tratto osseo situato sulla faccia interna del gomito).'
                },
                {
                    title: 'Compressione del Nervo Radiale',
                    description : 'La compressione del nervo radiale genera un dolore tagliente, penetrante o trafittivo sul dorso dell avambraccio e della mano e sul lato del gomito. Il dolore si manifesta quando il paziente cerca di estendere il polso e le dita.'
                }
            ]
        },
        {   title : 'Osteoartrosi',
            mainImg: Artrosi,
            secondaryimg: Artrosi,
            url: '/Work/Osteoartrosi',
            awards : [
                {
                    title: 'Artrosi di Polso',
                    description : 'La artrosi del polso può comparire a distanza di una trauma, in occasione del quale era avvenuta la lesione di uno o più legamenti del carpo (soprattutto del legamento Scafo-Lunato) o la frattura dell osso scafoide. Può essere conseguente ad esiti di fratture complesse del polso con danno della cartilagine articolare.'
                },
                {
                    title: 'Artrosi Carpo',
                    description : 'La artrosi del polso può comparire a distanza di una trauma, in occasione del quale era avvenuta la lesione di uno o più legamenti del carpo (soprattutto del legamento Scafo-Lunato) o la frattura dell osso scafoide. Può essere conseguente ad esiti di fratture complesse del polso con danno della cartilagine articolare'
                },
                {
                    title: 'Artrosi Anca',
                    description : 'Artrosi dell’anca è un disturbo comune che colpisce le articolazioni ed è causato da una perdita di cartilagine. La cartilagine è il rivestimento sopra le estremità delle ossa che serve a fornire una superficie di scorrimento liscia. Quando questa superficie cartilaginea viene consumata o distrutta, l osso sottostante viene esposto.'
                },
                {
                    title: 'Artrosi Ginocchio',
                    description : 'La gonartrosi, ovvero l’artrosi del ginocchio, è una patologia cronica che si sviluppa a livello articolare e presenta lesioni degenerative a carico della cartilagine articolare che provocano progressivamente dolore, difficoltà nei movimenti e, in casi più severi, deformazione dell’articolazione stessa'
                }
            ]
        },
        {   title : 'Tendinopatie',
            mainImg: Tendini,
            secondaryimg: Tendini,
            url: '/Work/Tendinopatie',
            awards : [
                {
                    title: 'Epicondiliti',
                    description : 'L’epicondilite è una particolare tendinopatia a carico dei tendini estensori del polso e delle dita. L’infiammazione origina quando i tendini che si inseriscono sull’epicondilo laterale omerale all’altezza del gomito, subiscono una degenerazione che scompagina le fibre elastiche e le sostituisce con fibre cicatriziali.'
                },
                {
                    title: 'Tendinosi mano-polso',
                    description : 'La tendinite al polso è un processo infiammatorio che può avere carattere degenerativo. '
                },
                {
                    title: 'Lesione cuffia dei rotatori',
                    description : 'lacerazioni, strappi nel tessuto tendineo con disinserzione dalla superficie ossea.'
                }
            ]
        },
        {   title : 'Traumatologia',
            mainImg: Frattura,
            secondaryimg: Frattura,
            url: '/Work/Traumatologia',
            awards : [
                {
                    title: 'Fratture',
                    description : 'Per frattura si intende una interruzione dell’integrità strutturale dell’osso che può essere di origine traumatica o spontanea (patologica). Nel caso di un trauma, l’osso si frattura quando il trauma ha entità tale da superare i limiti di resistenza dell’osso stesso'
                },
                {
                    title: 'Lesioni tendinee',
                    description : ''
                },
                {
                    title: 'Lesioni cutanee',
                    description : ''
                },
            ]
        },
        {   title : 'Certificazioni Assicurative',
            mainImg: assicurazioni,
            secondaryimg: assicurazioni,
            url: '/Work/Assicurative',
            awards : [
                {
                    title: 'Valutazioni post traumatiche',
                    description : ''
                },
                {
                    title: 'Valutazioni post traumatiche',
                    description : ''
                },
                {
                    title: 'Sportive ',
                    description : ''
                }
                
            ]
        },
    ]
}