import React from "react";
// GlobalStyled
import GlobalStyled from "./components/GlobalStyled";
import Nav from "./components/Nav"
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import OurWork from "./pages/OurWork";
import WokrDetails from "./pages/WokrDetails";
import ScroollTop from './components/ScroollTop'
import Formation from './components/Formation'
import Answer from './components/Answer'
import TemporaryAnswarePage from './components/TemporaryAnswarePage'

import Footer from './Footer'
//Router
import { Switch, Route, useLocation} from 'react-router-dom'

//Animation
//import from know when the component mount for the exit transiotion ( detect when the user leaves the page for start animation)
import { AnimatePresence } from 'framer-motion'

function App() {

  // object that get the jey of the page where the user are for detect when he leave the page and start transition whit AnimatePresence

  const location = useLocation()
  return (
    <div >
      <GlobalStyled/>
      
      <Nav/>
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path='/' exact>
            <AboutUs/>
          </Route>
        
        <Route path='/Work' exact>
        <OurWork/>
        </Route>

        <Route path='/Work/:id'>
          <WokrDetails/>
        </Route>

        <Route path='/Formation'  >
        <Formation />
        </Route>

        <Route path='/answer' >
        <TemporaryAnswarePage />
        </Route>

        <Route>
        <ContactUs path='/Contact' />
        </Route>
        

     

      </Switch>
    </AnimatePresence>
    <Footer/>
     
     
    </div>
  );
}

export default App;
