import React from 'react'


import piastrinico from '../img/piastrinico.jpg'
import Frattura from '../img/Frattura.jpg'
import Tendini from '../img/Tendini.jpg'
import TunnelCarpale from '../img/TunnelCarpale.jpg'
import Artrosi from '../img/Artrosi.jpeg'
import assicurazioni from '../img/assicurazioni.jpg'

import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { useScroll } from '../components/useScroll'
import  ScroollTop from '../components/ScroollTop'


//Animation
import {motion} from 'framer-motion'
import {pageAnimation, fade, photoAnim, lineAnim, slider, sliderContainer} from '../animation'


function OurWork() {
    const [element, controls] = useScroll()
    const [element2, controls2] = useScroll()
    const [element3, controls3] = useScroll()
    const [element4, controls4] = useScroll()
    const [element5, controls5] = useScroll()
    const [element6, controls6] = useScroll()

    const PatologyObj  = [
        {   name : 'Gel Piastrinico',
            image: piastrinico,
            animrefelm : element,
            animrefctrl : controls,
            pathName : '/Work/Gel'
        },
        {   name : 'Sindromi canalicolari',
            image: TunnelCarpale,
            animrefelm : element2,
            animrefctrl : controls2,
            pathName : '/Work/canalicolari'
        },
        {   name : 'Osteoartrosi',
            image: Artrosi,
            animrefelm : element3,
            animrefctrl : controls3,
            pathName : '/Work/Osteoartrosi'
        },
        {   name : 'Tendinopatie',
            image: Tendini,
            animrefelm : element4,
            animrefctrl : controls4,
            pathName : '/Work/Tendinopatie'
        },
        {   name : 'Traumatologia',
            image: Frattura,
            animrefelm : element5,
            animrefctrl : controls5,
            pathName : '/Work/Traumatologia'
        },
        {   name : 'Certificazioni Assicurative',
            image: assicurazioni,
            animrefelm : element6,
            animrefctrl : controls6,
            pathName : '/Work/Assicurative'
        },
    ]

    return (

        <Work variants={pageAnimation} initial='hidden' animate='show' exit='exit'>
            <motion.div variants={sliderContainer}>
                <Frame1 variants={slider}></Frame1>
                <Frame2 variants={slider}></Frame2>
                <Frame3 variants={slider}></Frame3>
                <Frame4 variants={slider}></Frame4>
            </motion.div>
               {PatologyObj.map((Pat) => 
               <Patology ref={Pat.animrefelm} variants={fade} animate={Pat.animrefctrl} initial='hidden'>
                    <motion.h2 variants={fade}>{Pat.name}</motion.h2>
                        <motion.div variants={lineAnim} className='line'></motion.div>
                    <Link to={Pat.pathName}>
                        <Hide>
                        <motion.img variants={photoAnim} src={Pat.image} alt='gel_piastrinico'/>     
                        </Hide>
                    </Link>  
               </Patology>
               )};
              <ScroollTop/>
        </Work>
    )
}

const Work = styled(motion.div)`
min-height: 100vh;
overflow: hidden;
padding: 5rem 10rem;
h2 {
    padding: 1rem 0rem;
    @media ( max-width: 1300px) {
         font-size: 3rem
        }
};
@media ( max-width: 1300px) {
         padding: 2rem 2rem;
         
        }
`

const Patology = styled(motion.div)`
padding-bottom: 10rem;
.line {
    height: 0.5rem;
    background: #1ADDF0;
    margin-bottom: 3rem;
}
img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
};
@media ( max-width: 1300px) {
    padding-bottom: 3rem;
         
        }
`

const Hide = styled.div`
    overflow: hidden;
`

const Frame1 = styled(motion.div)`
    position: fixed;
    left: 0;
    top: 10%;
    width: 100%;
    height: 100vh;
    background: #fffebf;
    z-index: 2;
`

const Frame2 = styled(Frame1)`
    background: #ff8efb;
`

const Frame3 = styled(Frame1)`
    background: #8ed2ff;
`
const Frame4 = styled(Frame1)`
    background: #8effa0;
`



export default OurWork
