import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {StyledAbout} from '../styles';
import Toggle from './Toggle.js'
import {FormObj} from '../FormObj'
//Animation
import {motion} from 'framer-motion'
import {pageAnimation, titleSecondAnim} from '../animation'


function Formation() {

    const [formation, setFormation] = useState(FormObj)
    const [formSpec, setFormSpec] = useState(null)

    
    useEffect(() => {
        const SubInfo =  formation.map((Form) => Form);
        setFormSpec(SubInfo)
        formSpec === null ? console.log('nothing') : console.log(formSpec.years)
        
        
    }, [formation])
    
    
    return (
        <Faq>
        {formSpec && (
            <ContactStyles exit variants={pageAnimation} 
            initial='hidden' animate='show' 
            exit='exit' 
            style={{background : "#fff"}}>
                <TitleStyled>
                    <Hide>
                        <motion.h2 variants={titleSecondAnim}>Formazione & CURRICULUM VITAE</motion.h2>
                    </Hide>
                </TitleStyled>
               
                    <Hide>
                    <Social variants={titleSecondAnim}>      
                    {formation.map((Form, i) => (  
                        <motion.div>    
                            <Toggle title={Form.title} id="Toggle" className="Toggle_El">                            
                                {formation[i].years.map((formDetails) => (
                                    <SpecElement>
                                    <ul>
                                        <TextSpecEl >{formDetails.year}</TextSpecEl>   
                                               {formDetails.text.map((textEl) => (
                                                        <li> <TextSpecEl>{textEl}</TextSpecEl></li>                                
                                                    ))}                          
                                    </ul>   
                                    </SpecElement>
                                
                                ))} 
                                </Toggle>
                        </motion.div>
                    ))}
    
                    </Social>
                    </Hide>
            </ContactStyles>
        )}
        </Faq>
       
    )
};

const ContactStyles = styled(motion.div)`
   padding: 5rem 10rem;
   color: #353535;
   min-height: 90vh;
   @media ( max-width: 1500px) {
         padding: 1rem;
         font-size: 1rem;
        }
`

const TitleStyled = styled.div`
   margin-bottom: 4rem;
   color: black;
   @media ( max-width: 1300px) {
    h2 {
       font-size: 3rem
   };
        }
`

const Hide = styled.div`
   overflow: hidden;
   h2 {
       text-align: center;
   };
   @media ( max-width: 1300px) {
    h2 {
       font-size: 3rem
   };
        }
`

const Circle = styled.div`
   border-radius: 50%;
   width: 0.5rem;
   height: 0.5rem;
   background: #353535;
   margin-right: 2rem;
   background: #1ADDF0;
`

const Social = styled.div`
   
   align-items: center;
   h2{
        margin: 2rem;
        cursor: pointer;
   };
   .Toggle_El {
       padding: 2rem 2rem
   }

`

const List = styled.div`
   display: flex;
   flex-direction: column;
   align-items: left;
   h2{
        margin: 2rem
   }

`

const SpecElement = styled.div`
   display: flex;
   align-items: center;
   padding: 1rem 0rem;
   
`

const TextSpecEl = styled.div`
   margin: 2rem ;
   font-weight: bold;
   li {
       font-weight: lighter;
   }
`

const Faq = styled(StyledAbout)`
    display: block;
    span {
        display: block
    }
    h2 {
        padding-bottom: 2rem;
        font-weight: lighter;
        @media ( max-width: 1300px) {
         font-size: 3rem
         
        }
    }
    .question {
        padding: 3rem 3rem;
        cursor: pointer
    }
   
`;
export default Formation
