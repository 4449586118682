import React from 'react';
// Scroll animation components
import { useScroll } from '../components/useScroll'
import { scrollReveal } from '../animation'
import {Link} from 'react-router-dom'

// Import Icons
import diaphragm from '../img/diaphragm.svg';
import doctor from '../img/doctor.png';
import provetta from '../img/provetta.png';
import medical from '../img/medical.png';

// Dtyled Components

import {StyledAbout, StyledDescription, StyledImage, StyledHide} from '../styles';
import styled from 'styled-components';

function ServiceSections() {
    const [element, controls] = useScroll()
  
    return (
        <Services variants={scrollReveal} animate={controls} initial='hidden' ref={element}>
            <Description>
                <h2>Dr. Berloco <span>Professionalità e Esperienza</span> </h2>
                <Cards>
                    <Card>
                        <div className='icon'>
                            <img src={provetta} alt='icon'></img>
                            <h3>GEL PIASTRINICO</h3>
                            
                        </div>
                        <p>Il potenziale valore terapeutico delle Piastrine e la versatilità dei loro prodotti 
                            hanno recentemente stimolato la ricerca e l’interesse nel campo della medicina rigenerativa. </p>
                            <Link to='/Work/Gel'>
                            <button className='Service_button'>Maggiori Info</button>
                            </Link>
                           
                    </Card>

                    <Card>
                        <div className='icon'>
                            <img src={medical} alt='icon'></img>
                            <h3>PATOLOGIE TRATTATE</h3>
                        </div>
                        <p>Il dott. Berloco è specializzato nella cura di numerose patologie: 
                            sindromi canalicolari, osteoartrosi, tendinopatie, traumatologia, e rilascia certificazioni assicurative.</p>
                            <Link to='/Work'>
                            <button className='Service_button'>Maggiori Info</button>
                            </Link>
                    </Card>

                    <Card>
                        <div className='icon'>
                            <img src={doctor} alt='icon'></img>
                            <h3>IL DOTT. BERLOCO RISPONDE</h3> 
                        </div>
                        <p>Il dott. Berloco risponde alle domande frequenti sulle patologie ortopediche 
                            e traumatologiche. Puoi inviare la tua domanda scrivendo a Questo indirizzo</p>
                            <Link to='/answer'>
                            <button className='Service_button'>Maggiori Info</button>
                            </Link>
                    </Card>

                    
                </Cards>
            </Description>
            
        </Services>
    )
};

const Services = styled(StyledAbout)`

 background: #2F2952;
    h2 {
        padding-bottom: 5rem;
    }
    p {
        width: 80%;
        padding: 2rem 0rem 4rem 0rem;
   }
  
    }
`;

const Description = styled(StyledDescription)`
 
    h2 {
        color: white
    }
   
`;

const Cards = styled.div`
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 @media ( max-width: 1300px) {
         justify-content: center;
        }
`;

const Card = styled.div`
 flex-basis: 30rem;
 align-items: center;
 @media ( max-width: 1300px) {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center
        }
 .icon{
     display: flex;
     align-items: center;
      
     @media ( min-width: 1300px) {
        width: 100%;
        }
 }
 h3 {
     margin-left: 1rem;
     color: white;
     padding: 1rem;
 }
`

export default ServiceSections
