import React from 'react'
// useAnimations is use for reference elements that be animated when a event occurred ( scroll, click ecc..)
import { useAnimation } from 'framer-motion';
import { useInView} from 'react-intersection-observer';

export const useScroll = () => {
    
    const controls = useAnimation()
    // Status use for detect the scrolling page - threshold define when the viwe comes true 
    const [element, view] = useInView({threshold : 0.3})

    if (view) {
        controls.start('show')
    } else {
        controls.start('hidden')
    }

    return [element, controls]
}


