
export const FormObj = () => {
    return  [
       
        {   title : 'ESPERIENZA OSPEDALIERA (Clicca per maggiori info)',
            years : [
                {
                    year: 'Dal 01/01/2019 ad oggi',
                    text: ["Responsabile Rischio Clinico del Dipartimento Area Chirurgica – AUSL Umbria 2 Azienda Unità Sanitaria Locale Umbria n.2 - Sede legale e Direzione Generale: Terni, viale Donato Bramante 37 05100 Terni (TR)(www.uslumbria2.it)",
                    "Attività svolta: Attività di valutazione del rischio clinico operatorio e post operatorio svolta con autonomia gestionale ed organizzativa e responsabilità diretta di gestione delle risorse umane, tecniche e finanziarie del Dipartimento."]
                },
                {
                    year: 'Dal 16/11/2012 ad oggi',
                    text: ['Incarico Professionale per la Chirurgia della Mano - AUSL Umbria 2 – Ospedale San Giovanni Battista – Foligno (PG) quale Centro riconosciuto dalla Società Italiana di Chirurgia della Mano (www.sicm.it).', 
                            'Azienda Unità Sanitaria Locale Umbria n.2 - Sede legale e Direzione Generale: Terni, viale DonatoBramante 37 05100 Terni (TR)(www.uslumbria2.it)',
                            'Incarico conferito con delibera n.396 del 15.11.2012, con decorrenza del 16.11.2012 e poi rinnovato fino ad oggi',
                            'Attività svolta: (i) Attività ambulatoriale e chirurgica delle patologie della mano/arto superiore:trattamento chirurgico delle patologie osteoarticolari, tendinee e nervose della mano; (ii) Miglioramento aziendale dei percorsi chirurgici con rispetto delle linee guida della Società Italiana di Chirurgia della mano; (iii) Partecipazione a corsi e congressi inerenti l’argomento e giornate di promozione della salute della mano; (iv) Incremento dell’attività chirurgica negli ultimi 3 anni, individuando per singola patologie il percorso più idoneo (D.O.-D.S.-Chirurgia Ambulatoriale)']
                },
                {
                    year: 'Dal 15/11/2010 ad oggi',
                    text: ['Dirigente Medico 1^ livello – AUSL Umbria 2 – Ospedale San Giovanni Battista – Foligno (PG) Reparto di Ortopedia e Traumatologia',
                            'Ospedale San Giovanni Battista - Via Massimo Arcamone, 06034 Foligno (PG)']
                },
                {
                    year: 'Dal 2005 al 2009',
                    text: ['Frequenza presso la Struttura Complessa di Chirurgia della Mano e Microchirurgia ell’Azienda Ospedaliera “S.Maria” di Terni – Università degli Studi di Perugia',
                    'Azienda Ospedaliera S.Maria - Viale Tristano di Joannuccio, 05100 Terni (TR)', 
                   ' Attività svolta: Partecipazione al lavoro di reparto, di sala operatoria, di ambulatorio e di pronto soccorso.' ]
                },
                {
                    year: 'Dal 2002 al 2004',
                    text: ['Internato presso la Clinica Ortopedica dell’Università degli Studi di Perugia – Ospedale S.Maria della Misericordia – Perugia', 
                            'Azienda Ospedaliera S. Maria della Misericordia - Piazzale Giorgio Menghini, 1, 06129 Perugia (PG).']
                },
                {
                    year: 'Dal 1994 al 1996',
                    text: ['Tirocinio presso il Reparto di “Chirurgia Generale e d’Urgenza” - Ospedale S. Maria della Misericordia - Piazzale Giorgio Menghini, 1, 06129 Perugia (PG)']
                }
            ]
        },
        
        {   title : 'ESPERIENZA DIRIGENZIALE EXTRAOSPEDALIERA(Clicca per maggiori info)',
        years : [
            {
                year: 'Dal 2006 al 2018',
                text: ['Responsabile Sanitario – Sovrano Ordine Militare di Malta – Delegazione di Perugia e Terni', 
                'Sovrano Ordine Militare di Malta – Delegazione di Perugia e Terni - Via C. Colombo, 36  06127 Perugia (https://www.ordinedimaltaitalia.org/gran-priorato-di-roma/perugia-terni)',
                'Attività svolta: Responsabile sanitario degli istituti religiosi della Delegazione di Perugia e Terni - coordinamento, con autonomia gestionale ed amministrativa, e responsabilità diretta della gestione delle risorse umane, tecniche e finanziarie, in relazione all’erogazione di prestazioni sanitarie in regime di volontariato da parte di oltre una decina di medici specialisti in diverse discipline ed altrettanto personale infermieristico (oltre 100 prestazioni sanitarie erogate così suddivise: Ortopedia 45 prestazioni, Geriatria 20 prestazioni ,Ginecologia 12 prestazioni, Urologia 10 prestazioni, Psichiatria 8, altre specialistiche >15) – Implementazione dei processi sanitari all interno di tali strutture.']
            },
            {
                year: 'Dal 2008 al 2017',
                text: ['Responsabile Sanitario per il Corpo Italiano di Soccorso del Sovrano Militare Ordine di Malta  (CISOM)- Gruppi di Soccorso di Perugia, di Spoleto e di Bettona', 
            '1) (Gruppo Perugia) Piazza Navigatori, 36 - Ferro di Cavallo - Perugia (PG); 2) (Gruppo Spoleto) Via dei Tessili, 15 06049 Spoleto (PG); 3) (Gruppo Bettona) Via Emilia, 25, 06084 Passaggio (PG) (www.cisom.org)',
                'Attività svolta: Gestione e coordinamento, con autonomia organizzativa e gestionale delle attività di soccorso svolte dai vari Gruppi. Diretta responsabilità delle risorse umane, tecniche e finanziarie dei vari gruppi per attività di soccorso anche in situazioni emergenziali. Formazione sanitaria di tutti i volontari dei gruppi di soccorso, i quali hanno operato insieme al sottoscritto nelle emergenze nazionali: (i) Sisma Emilia 2012; (ii) Alluvione Genova 2014; (iii) Emergenza Lampedusa 2015-2016; (iv) Sisma Centro Italia 2016; (v) Altri eventi in cui è intervenuta la Protezione Civile nel 2017.']
            },
            {
                year: '08/2015',
                text: ['Medico SAR Operation – Lampedusa', 'Attività svolta: soccorso sanitario ai migranti']
            },
            {
                year: 'Dal 2019 ad oggi',
                text: ['Scuola Italiana Protezione Civile – Consigliere del Consiglio di Amministrazione', 
                'Scuola Italiana di Protezione Civile - Via Aldo Palazzeschi, 28 Loc. Taverne di Corciano 06073 Corciano (PG) ( https://www.scuolaitalianaprotezionecivile.it/)',
                    'Attività svolta: Gestione e coordinamento, con autonomia organizzativa e gestionale delle attività della Scuola, con riferimento alla formazione medica emergenziale di base e specialistica offerta dalla Scuola a medici, infermieri e civili operanti nel settore. Diretta responsabilità della pianificazione delle risorse umane, tecniche e finanziarie della Scuola']
            },
            {
                year: 'Dal 2013 ad oggi',
                text: ['Associazione Ortopedici Traumatologici Ospedalieri d’Italia (O.T.O.D.I) – Segretario e Tesoriere – Direttivo Regionale Umbria', 
                        'Via Giuseppe Di Vittorio 2, 40057 Cadriano di Granarolo (BO)(sede legale)(www.otodi.it)' , 
                        'Attività svolta: Gestione e coordinamento, con autonomia organizzativa e gestionale delle attività portate avanti dal Direttivo Regionale finalizzate ad ottimizzazione i processi ospedalieri nel settore ortopedico, in attuane delle best practise, inerenti anche la più efficiente gestione delle risorse umane, tecniche e finanziarie. Pianificazione e realizzazione dell’attività congressuale dell’Associazione, con diretta gestione e responsabilità delle risorse finanziarie, tecniche ed umane associazione. In particolare, l’incarico di tesoreria comprende la gestione e la rendicontazione delle risorse finanziare, tramite bilanci preventivi e consuntivi. Nel corso dell attività finora svolta dal 2013, il sottoscritto ha raggiunti importanti risultati quali (i) un notevole incremento dei soci associazione, (ii) un miglioramento delle conoscenze e competenze dei soci; (iii) un incremento degli utili economici associazione grazie ad una efficiente gestione delle risorse economiche.']
            },
            {
                year: 'Dal 2016 ad oggi',
                text: ['Opere Pie Riunite di Perugia – Consigliere del Consiglio di Amministrazione' , 'Via Campo di Marte 9 06124 Perugia (PG)(www.operepieriunite.it)',
                        'Attività svolta: Gestione e coordinamento, con autonomia organizzativa e gestionale delle attività assistenziali ed educative dell’Ente poste in essere in favore di minori orfani o svantaggiati - Responsabilità diretta in merito alla gestione delle risorse tecniche, umane e finanziarie dell Ente – Ottimizzazione dei processi.']
            },
            {
                year: '2008',
                text: ['Chief Medical Officer della Federazione Internazionale di Motociclismo (FIM) (Italia)']
            },
            {
                year: '2003',
                text: ['Guardia Medica presso al Casa di Cura “Elledue” Perugia (PG).']
            },
   
           
        ]
    },
    {   title : 'ISTRUZIONE E FORMAZIONE (Clicca per maggiori info)',
    years : [
        {
            year: 'SPECIALIZZAZIONE E LAUREA',
            text: ['']
        },
        {
            year: '2005 (Settembre)',
            text: ['Segreteria Scientifica “43° Congresso Nazionale Società Italiana Chirurgia della Mano”-Perugia.']
        },
        {
            year: '2006',
            text: ['Società Italiana Chirurgia della Mano - Socio Aderente.']
        },
        
        {
            year: '2007 (Novembre)',
            text: ['Tutor Corso Teorico-Pratico “Le Fratture del Radio Distale”, Terni.']
        },
        
        {
            year: '2007',
            text: ['Tutor Laureando Medicina per compilazione Tesi di Laurea.']
        },
        {
            year: '2008',
            text: ['Chief Medical Officer-Federazione Internazionale Moto.', 'Comitato scientifico per Chirurgia Mano del XXI Congresso Nazionale della  Società Polispecialistica Giovani Chirurghi.']
        },
        {
            year: '2009',
            text: ['Contratto Libero Professionista presso S.C.Ortopedia e Traumatologia, USL 2 FOLIGNO.']
        },
        {
            year: '2010',
            text: ['Medico CISOM Perugia-Spoleto', 'Resp .Sanitario Ordine Malta- Perugia', 'Dirigente medico ASL3 Foligno-Pg-Medico Chirurgo-Sc.Ortopedia']
        },
        {
            year: '2012',
            text: ['Responsabile Sanitario Corpo Soccorso Ordine di Malta,Perugia-Spoleto', ' Segretario Umbria Ortopedici Ospedalieri', 'Socio Ordinario Società Italiana Chirurgia Mano',
        'Incarico Profesisonale per Chirurgia della Mano, Foligno']
        },
        {
            year: '2013',
            text: ['Invite Professor Università Khazakistain']
        },

        

        
       
    ]
},

{   title : 'COMPETENZE INFORMATICHE (Clicca per maggiori info)',
years : [
    {
        year: 'Operating Systems',
        text: ['DOS, Windows 1995/98, 2000, NT, XP Professional,Vista, Mac OSX Leopard.']
    },
    {
        year: 'Microsoft Applications',
        text: ['Word, Excel, PowerPoint, Access, Explorer, Safari, Outlook']
    },
    {
        year: 'Mac Applications',
        text: ['Pages, Keynote, Numbers.']
    },
    
    {
        year: 'Adobe Applications',
        text: ['Reader, Photoshop, Illustrator.']
    },
    {
        year: 'Web Design',
        text: ['WebSite X5 Accessibile.']
    },
]
},
        
    ]
    }