import React, {Component, useState} from 'react'
// inject global styles from styled-components. border-sizing, body. 

import styled, {injectGlobal} from 'styled-components'

//Animation
import {motion} from 'framer-motion'
import {pageAnimation, titleSecondAnim} from '../animation'

// holds all components
import Main from '../components/ContactForm/views/Main/Main'
import Mailer from '../components/mailer'


const mapStyles = {
  width: '30%',
  height: '30%'
};

function ContactUs() {

    return (
        <ContactStyles exit variants={pageAnimation} 
        initial='hidden' animate='show' 
        exit='exit' 
        style={{background : "#fff"}}>
            <TitleStyled>
                <Hide>
                    <motion.h2 variants={titleSecondAnim}></motion.h2>
                </Hide>
            </TitleStyled>
                {/*<Main />*/}   
                <Mailer/>      
        </ContactStyles>
    )
};

const ContactStyles = styled(motion.div)`
  
   padding: 5rem 10rem;
   color: #353535;
   min-height: 90vh;
   @media ( max-width: 1500px) {
         padding: 1rem;
         font-size: 1rem;
        }
`

const TitleStyled = styled.div`
   margin-bottom: 4rem;
   color: black;
   h2{
    @media ( max-width: 1300px) {
         
         font-size: 3rem;
        }
   }
   @media ( max-width: 1300px) {
       text-align: center;
        }
`

const Hide = styled.div`
   overflow: hidden;
`

const Circle = styled.div`
   border-radius: 50%;
   width: 3rem;
   height: 3rem;
   background: #353535;
`

const Social = styled.div`
   display: flex;
   align-items: center;
   h2{
        margin: 2rem
   }

`

const Map = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between
`

export default ContactUs
