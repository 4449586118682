import React, { useState } from 'react'
import Toggle from './Toggle.js'
import { AnimateSharedLayout } from "framer-motion"

// Dtyled Components

import styled from 'styled-components';
import {StyledAbout} from '../styles';


function FaqSection() {
    //Array of state for each question for toggle the current selected question
    const [faqToggle, setFaqToggle] = useState([false,false,false,false,false])

    return (
        <Faq>
            <h2>Dettaglio <span>Patologie trattate</span> Clicca su ogni patologia per saperne di più</h2>
            <h4>Il dott. Berloco è specializzato nella cura di numerose patologie: sindromi canalicolari, osteoartrosi, tendinopatie, traumatologia, e rilascia certificazioni assicurative. Inoltre è specializzato nel trattamento chirurguco e non chirurgico di numerose patologie dell'apparato locomotore.</h4>
            <AnimateSharedLayout>
                <Toggle title="Sindromi canalicolari (Clicca per altre info)">
                    <div className='answare'>
                        <p>Sindrome del tunnel carpale</p>
                        <p>
                        Compressione del Nervo Ulnare
                        </p>
                        <p>
                        Compressione del Nervo Radiale
                        </p>
                    
                    </div>
                
                </Toggle>

                <Toggle title="Osteoartrosi (Clicca per altre info)">
        
                    <div className='answare'>
                        <p>Artrosi di Polso</p>
                        <p>Artrosi Carpo</p>
                        <p>Artrosi Anca</p>
                        <p>Artrosi Ginocchio</p>   
                </div>
                </Toggle>

                <Toggle title="Tendinopatie (Clicca per altre info)">
            
                    <div className='answare'>
                        <p>Epicondiliti</p>
                        <p>Tendinosi mano-polso</p>
                        <p>Lesione cuffia dei rotatori</p>
                </div>
                </Toggle>

                <Toggle title="Traumatologia (Clicca per altre info)">
                    <div className='answare'>
                        <p>Fratture</p>
                        <p>Lesioni tendinee</p>
                        <p>Lesioni cutanee</p>
                </div>
                </Toggle>

                <Toggle title='Certificazioni Assicurative (Clicca per altre info)'>

                    <div className='answare'>
                        <p>Valutazioni post traumatiche</p>
                        <p>Invalidità</p>
                        <p>Sportive</p>
                </div>

                </Toggle>
                </AnimateSharedLayout>
        </Faq>
    )
}

const Faq = styled(StyledAbout)`
    display: block;
    span {
        display: block
    }
    h2 {
        padding-bottom: 2rem;
        font-weight: lighter;
        @media ( max-width: 1300px) {
         font-size: 3rem
         
        }
    }
    .faq-line {
        background: #cccccc;
        height: 0.2rem;
        margin: 2rem 0rem;
        width: 100%;
    }
    .question {
        padding: 3rem 0rem;
        cursor: pointer
    }
    .answer {
        padding: 2rem 0rem;
        p {
            padding: 1rem 0rem;
        }

    }
`;

export default FaqSection
