import React from 'react'
import styled from 'styled-components'


const WrapperCompanyInfo=styled.div`
    background: #1ADDF0;
`; 

const CompanyName = styled.h3`
    margin: 1rem;
    text-align: center;
    color: #1ADDF0; 
    @media (min-width: 700px) {
        text-align: left; 
    }
`;

const WrapperList = styled.li`
    list-style: none;
    margin:1rem ;
    padding:0;
    font-size: 2rem;
    text-align: center;
    color: white;
    font-weight: bold;
    list-style: none;
    
    li {
        padding: 1rem;
    }
    @media (min-width: 700px) {
        text-align: left; 
        font-size: 2rem;
    }
`;


const CompanyInfo = () => (
    <WrapperCompanyInfo>
        <CompanyName>Dr. Michele Berloco</CompanyName>
        <WrapperList>
            
                <li>Foligno Ospedale (intramoenia)</li>
                <li>Numero Telefono : 0742.339.77.99</li>
                <li>Email : Info@micheleberloco.it</li>
            
            
        </WrapperList>
    </WrapperCompanyInfo>
)

export default CompanyInfo