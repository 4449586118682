import React from 'react'
import emailjs from 'emailjs-com'
import styled from 'styled-components'
import { css } from 'styled-components'


//import components
import Title from './ContactForm/components/Title'
import Form from './ContactForm/components/Form'
import Label from './ContactForm/components/Label'
import StyledButton from './ContactForm/components/Button'
import Input from './ContactForm/components/Input'
import Textarea from './ContactForm/components/Textarea'

function Mailer() {

function SendEmail(e) {
    e.preventDefault()
    console.log(e.target.email.value)
    emailjs.sendForm('francescobocchini91','template_mjqgspv', "#myEmailForm",
    'user_6zv6F19lVS9krK4QnjgZV').then(res=> {
        console.log(res)
        alert('il messaggio è stato inviato correttamente')
    }).catch(err => console.log(err))
}

    return (
        <div>
            <Title text="Contatta il Dr. Berloco" />
            <WrapperCompanyInfo>
                <CompanyName>Dr. Michele Berloco</CompanyName>
                <WrapperList>                
                        <li>Foligno Ospedale (intramoenia)</li>
                        <li>Numero Telefono : 0742.339.77.99</li>
                        <li>Email : Info@micheleberloco.it</li>    
                </WrapperList>
        </WrapperCompanyInfo>
        <div className='container border'
        style={{marginTop: '2rem', width:'95%', backgroundColor: '#1ADDF0', height:'80%'}}>
            
            <form className='row' style={{margin: '1rem 1rem 1rem 2rem'}} onSubmit={SendEmail} id='myEmailForm'>
                
                <label class="h3" style={{marginTop:'30px', color:'white', fontSize: '1.8rem'}}>Nome</label>
                <input type="text" name="name" className='form-control'/>

                <label class="h3" style={{marginTop:'30px', color:'white', fontSize: '1.8rem'}}>Cognome</label>
                <input type="text" name="subname" className='form-control'/>

                <label class="h3" style={{marginTop:'30px', color:'white', fontSize: '1.8rem'}}>Email</label>
                <input type="email" id="email" name="email" className='form-control'/>

                <label class="h3" style={{marginTop:'30px', color:'white', fontSize: '1.8rem'}}>Telefono</label>
                <input type="tel" id='phone' name="phone" className='form-control'/>

                <label class="h3" style={{marginTop:'30px', color:'white', fontSize: '1.8rem'}}>Messaggio</label>
                <textarea type="text" name="message" rows="4" className='form-control' style={{resize: 'vertical'}}/>
                <input type="submit" value="Invia Email" className='form-control btn btn-primary'
                style={{marginTop: '30px'}}/>


            </form>
        </div>
        </div>
    )
}

export default Mailer

const WrapperCompanyInfo=styled.div`
    background: #1ADDF0;
`; 

const CompanyName = styled.h3`
    margin: 1rem;
    text-align: center;
    color: #1ADDF0; 
    @media (min-width: 700px) {
        text-align: left; 
    }
`;

const WrapperList = styled.li`
    list-style: none;
    margin:1rem ;
    padding:0;
    font-size: 2rem;
    text-align: center;
    color: white;
    font-weight: bold;
    list-style: none;
    
    li {
        padding: 1rem;
    }
    @media (min-width: 700px) {
        text-align: left; 
        font-size: 2rem;
    }
`;

