import React, {useState, useEffect} from 'react';
import styled  from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PatologyObj } from '../PatologyObj';
import  ScroollTop from '../components/ScroollTop'

//Animation
import {motion} from 'framer-motion'
import {pageAnimation} from '../animation'


function WokrDetails() {
    const history = useHistory()
    const url = history.location.pathname
    const [patologyWork, setPatologyWork] = useState(PatologyObj)
    const [selectPatology, setSelectPatology] = useState(null)

    useEffect(() => {
        const currentPatology = patologyWork.filter((PatologyObj) => PatologyObj.url === url);
        setSelectPatology(currentPatology[0])
    }, [patologyWork , url])

    return (
        <>
        { // only if the movie is avalaible render out the content (trky for prevent error 'Cannot read property 'name' of null')
        selectPatology && (
            <Details variants={pageAnimation} initial='hidden' animate='show' exit='exit'>
            <Headline>
                <h2>{selectPatology.title}</h2>
                <img src={selectPatology.mainImg }/>
            </Headline>
            <Awards>
                {selectPatology.awards.map((award) => (
                    <Award title={award.title} description={award.description} key={award.title}/>
                ))}
            </Awards>
            {/*<ImageDisplay>
                <img src={selectPatology.secondaryimg}/>
            </ImageDisplay>*/}
            
            <ScroollTop />
        </Details>
        )}
        
        </>
    )
}

const Details = styled(motion.div)`
        
`

const Headline = styled.div`
    padding: 2rem 2rem
     min-height: 90vh ;
     padding-top: 20vh;
     position: relative;
     h2 {
         position: absolute;
         top: 10%;
         //left: 50%;
        //transform: translate(-50%, -10%);
        @media ( max-width: 1300px) {
         font-size: 3rem
        }
     }
     img {
         width: 100%;
         height: 70vh;
         object-fit: cover;
         
     }
     @media ( max-width: 1500px) {
        margin: 2rem 2rem;
        h2 {
            font-size: 2.5rem;
            font-weight: bold;
     }
`

const Awards = styled.div`
        min-height: 80vh;
        display: flex;
        margin: 5rem 10rem;
        align-items: center;
        justify-content: space-around;
            @media ( max-width: 1500px) {
            display: block;
            margin: 2rem 2rem
            }
    
`
const AwardStyle = styled.div`
        padding: 3rem;
        @media ( max-width: 1300px) {
            padding: 0rem;
            }
        h3 {
            font-size: 2rem;
            color: black;
        }
    .line{
        width: 100%;
        background: #1ADDF0;
        height: 0.5rem;
        margin: 1rem 0rem;
    }
    p {
        padding: 2rem 0rem;
    }
`

const ImageDisplay = styled.div`
     min-height: 40vh;
     img{
         width: 100%;
         height: 100%;
         object-fit: cover;
         
     }
`


//Award Component

const Award = ({title, description}) => {
    return (
        <AwardStyle>
            <h3>{title}</h3>
            <div className='line'></div>
            <p>{description}</p>
        </AwardStyle>
    )
}


export default WokrDetails
