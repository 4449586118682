import React from 'react'
import AboutSection from '../components/AboutSection'
import FaqSection from '../components/FaqSection'
import ServiceSections from '../components/ServiceSections'
import  ScroollTop from '../components/ScroollTop'

//Animation
import {motion} from 'framer-motion'
import {pageAnimation} from '../animation'

const AboutUs = () => {
    return (
        <motion.div variants={pageAnimation} initial='hidden' animate='show' exit='exit'>
            <AboutSection/>
            <ServiceSections/>
            <FaqSection/>
            <ScroollTop/>
        </motion.div>
    )
}

export default AboutUs
