import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import { useLocation } from 'react-router-dom'

function Nav() {
    const { pathname} = useLocation();

    return (
        <StyledNav>
           <h1>
               <Link id='Logo' to='/'>
               Dr. Michele Berloco
               </Link>
            </h1> 
               
           <ul>
               <li>
                   <Link to='/'>Home</Link> 
                   <Line transition={{duration: 0.75}} 
                   initial={{width: "0"}}
                   animate= {{width: pathname === "/" ? "100%" : "0%"}} />     
               </li>
               <li id="styled-li">
                   <Link to='/Work'>Patologie</Link>
                   <Link to='/Work'>Trattate</Link>
                   <Line transition={{duration: 0.75}} 
                   initial={{width: "0"}}
                   animate= {{width: pathname === "/Work" ? "100%" : "0%"}} />   
               </li>
               
               <li>
                   <Link to='/Formation'>Formazione</Link>
                   <Line transition={{duration: 0.75}} 
                   initial={{width: "0"}}
                   animate= {{width: pathname === "/Formation" ? "100%" : "0%"}} />  
               </li>

               <li>
                   <Link to='/Contact'>Contatti</Link>
                   <Line transition={{duration: 0.75}} 
                   initial={{width: "0"}}
                   animate= {{width: pathname === "/Contact" ? "100%" : "0%"}} />  
               </li>
              
           </ul>
        </StyledNav>
    )
}

const StyledNav = styled.nav`
position: sticky;
top: 0;
z-index: 10;
min-height: 10vh;
display: flex;
margin: auto;
justify-content: space-between;
align-items: center;
padding: 1rem 10rem;
background: #282828 ;
a {
    color: white;
    text-decoration: none;
} 
ul {
    display: flex;
    list-style: none;
}
#Logo {
    font-size: 1.5rem;
    font-family: "Lobster", cursive;
    font-weight: lighter;
    
}
li {
    padding-left: 4rem;
    position: relative;
    #styled-li {
        display: block;
    }

}
@media ( max-width: 1300px) {
         flex-direction: column;
         padding: 2rem 1rem;
         #logo {
             display: inline-block;
             margin: 2rem;
             padding-bottom: 2rem;
             
         }
         ul {
            justify-content: space-around;
         width: 100%;
         }
         
         li {
             padding: 0;
         }
        }
`;

const Line = styled(motion.div)`
height: 0.3rem;
background: #1ADDF0;
width: 0%;
position: absolute;
bottom: -80%;
@media ( max-width: 1300px) {
         left: 0%;
        }
`

export default Nav
