import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

//Funcation for scroll to the top everytime the url's changes

const ScroollTop = () => {
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0
        })
    }, [pathname])

    return null;
};

export default ScroollTop;
