import React from 'react'
import styled from 'styled-components'

function Footer() {
    return (
        <StyledFooter>
            <p>Copyright © 2021 Michele Berloco</p>
        </StyledFooter>
    )
};

const StyledFooter = styled.nav`
position: sticky;
flex-direction: column;
//top: 0;
z-index: 10;
min-height: 10vh;
display: flex;
margin: auto;
justify-content: space-between;
align-items: center;
padding: 1rem 10rem;
background: #282828 ;
@media ( max-width: 1300px) {
        
         padding: 2rem 1rem;
         #logo {
             display: inline-block;
             margin: 2rem;
             padding-bottom: 2rem;
             
         }
         ul {
            justify-content: space-around;
         width: 100%;
         }
         
         li {
             padding: 0;
         }
        }
`;


export default Footer
